html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root,
#app-container,
.main-container,
.main-content {
    height: 100%;
    width: 100%;
    display: flex;
}

.ql-picker.ql-font .ql-picker-label[data-value='SansSerif']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='SansSerif']::before
{
    font-family: sans-serif;
    content: 'Sans Serif' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Serif']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Serif']::before
{
    font-family: Serif, sans-serif;
    content: 'Serif' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Arial']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Arial']::before
{
    font-family: Arial, sans-serif;
    content: 'Arial' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='ArialNarrow']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='ArialNarrow']::before
{
    font-family: Arial Narrow, sans-serif;
    content: 'Arial Narrow' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Times']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Times']::before
{
    font-family: Times, serif;
    content: 'Times' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='TimesNewRoman']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='TimesNewRoman']::before
{
    font-family: Times New Roman, serif;
    content: 'Times New Roman' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Helvetica']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Helvetica']::before
{
    font-family: Helvetica, sans-serif;
    content: 'Helvetica' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Courier']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Courier']::before
{
    font-family: Courier, monospace;
    content: 'Courier' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='CourierNew']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='CourierNew']::before
{
    font-family: Courier New, monospace;
    content: 'Courier New' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Verdana']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Verdana']::before
{
    font-family: Verdana, sans-serif;
    content: 'Verdana' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Candara']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Candara']::before
{
    font-family: Candara, sans-serif;
    content: 'Candara' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Geneva']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Geneva']::before
{
    font-family: Geneva, sans-serif;
    content: 'Geneva' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Calibri']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Calibri']::before
{
    font-family: Calibri, sans-serif;
    content: 'Calibri' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Optima']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Optima']::before
{
    font-family: Optima, sans-serif;
    content: 'Optima' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Cambria']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Cambria']::before
{
    font-family: Cambria, serif;
    content: 'Cambria' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Garamond']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Garamond']::before
{
    font-family: Garamond, serif;
    content: 'Garamond' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Perpetua']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Perpetua']::before
{
    font-family: Perpetua, serif;
    content: 'Perpetua' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Monaco']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Monaco']::before
{
    font-family: Monaco, monospace;
    content: 'Monaco' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Didot']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Didot']::before
{
    font-family: Didot, serif;
    content: 'Didot' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='BrushScript']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='BrushScript']::before
{
    font-family: Brush Script, cursive;
    content: 'Brush Script' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='LucidaBright']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='LucidaBright']::before
{
    font-family: Lucida Bright, serif;
    content: 'Lucida Bright' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Copperplate']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='Copperplate']::before
{
    font-family: Copperplate, fantasy;
    content: 'Copperplate' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='ComicSans']::before, 
.ql-picker.ql-font .ql-picker-item[data-value='ComicSans']::before
{
    font-family: Comic Sans MS, Comic Sans, sans-serif;
    content: 'Comic Sans' !important;
}

.ql-picker.ql-expanded .ql-picker-options {
    max-height: 200px;
    overflow-y: auto;
}

.ql-picker.ql-expanded .ql-picker-options {
    width: 120px !important;
}

.ql-picker.ql-font {
    width: 135px !important;
}

.app .ql-editor {
  font-family: sans-serif;
}

/** IMPORTANT!! */
/** Anytime the following css gets changes, copy it till the end of line (in the comment below) */
/** minified it, and then update (by replacing) the airteach-student-ui global.js file, qlFonts constant. */
/** START OF LINE TO COPY */
/** React Quill font CSS */
/* Set default font-family */

.app .ql-font-SansSerif {
  font-family: sans-serif;
}

.ql-font-Serif {
    font-family: Serif, sans-serif;
}

.ql-font-Arial {
    font-family: Arial, sans-serif;
}

.ql-font-ArialNarrow {
    font-family: Arial Narrow, sans-serif;
}

.ql-font-Times {
    font-family: Times, serif;
}

.ql-font-TimesNewRoman {
    font-family: Times New Roman, serif;
}

.ql-font-Helvetica {
    font-family: Helvetica, sans-serif;
}

.ql-font-Courier {
    font-family: Courier, monospace;
}

.ql-font-CourierNew {
    font-family: Courier New, monospace;
}

.ql-font-Verdana {
    font-family: Verdana, sans-serif;
}

.ql-font-Candara {
    font-family: Candara, sans-serif;
}

.ql-font-Geneva {
    font-family: Geneva, sans-serif;
}

.ql-font-Calibri {
    font-family: Calibri, sans-serif;
}

.ql-font-Optima {
    font-family: Optima, sans-serif;
}

.ql-font-Cambria {
    font-family: Cambria, serif;
}

.ql-font-Garamond {
    font-family: Garamond, serif;
}

.ql-font-Perpetua {
    font-family: Perpetua, serif;
}

.ql-font-Monaco {
    font-family: Monaco, monospace;
}

.ql-font-Didot {
    font-family: Didot, serif;
}

.ql-font-BrushScript {
    font-family: Brush Script, cursive;
}

.ql-font-LucidaBright {
    font-family: Lucida Bright, serif;
}

.ql-font-Copperplate {
    font-family: Copperplate, fantasy;
}

.ql-font-ComicSans {
    font-family: Comic Sans MS, Comic Sans, sans-serif;
}

/** END OF LINE TO COPY */